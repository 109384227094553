import { v4 as uuid4 } from 'uuid';

import styles from './Implement.module.scss';
import Container from '@/components/Container/Container';
import ServicesTop from '@/components/ServicesTop/ServicesTop';
import ServicesCard from '@/components/ServicesCard/ServicesCard';

const implementItems = [
  {
    id: uuid4(),
    title: 'audit',
    subtitle: <><span className={styles.itemSubtitle1}>assess.</span><span className={styles.itemSubtitle2}>learn.</span><span className={styles.itemSubtitle3}>advise.</span></>,
    text: 'Possibly some text needed here to explain it better?? e.g. Automate time consuming tasks, remove barriers that limit speed of execution and implement AI driven tooling to save time.',
  },
  {
    id: uuid4(),
    title: 'implement',
    subtitle: <><span className={styles.itemSubtitle1}>implement.</span><span className={styles.itemSubtitle2}>integrate.</span><span className={styles.itemSubtitle3}>accelerate.</span></>,
    text: 'Possibly some text needed here to explain it better?? e.g. Automate time consuming tasks, remove barriers that limit speed of execution and implement AI driven tooling to save time.',
  },
];

function Implement() {
  const servicesTop = {
    title: 'ai.implement',
    text: 'Once we’ve understood your challenges, aspirations and your current ways of working we will propose implementations of ai that will help accelerate your business. Our team of designers and developers will bring to life these implementations to ensure they maximise customer experience, integrate to your existing ecosystem and accelerate your business aspirations.',
  };

  return (
    <section className={styles.implement} id="implement">
      <Container>
        <ServicesTop data={servicesTop} link="#build" />

        <div className={styles.cards}>
          {implementItems.map((item, index) => (
            <div className={index === 0 ? styles.cardWithArrow : ''} key={item.id}>
              <ServicesCard data={item} index={index + 1} withArrow />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default Implement;
