import styles from './ServicesCard.module.scss';

function ServicesCard({ data, index, withArrow }) {
  return (
    <div className={`${styles.servicesCard} ${withArrow ? styles.servicesCardWithArrow : ''}`}>
      <p className={styles.counter}>{index >= 10 ? index : `0${index}`}</p>

      <div>
        <h3 className={styles.title}>ai.<span className={styles.titlePart2}>{data.title}</span></h3>
        <p className={styles.subtitle}>{data.subtitle}</p>
        <p className={styles.text}>{data.text}</p>
      </div>
    </div>
  );
}

export default ServicesCard;
