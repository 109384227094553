import * as React from 'react';

function Icon(props) {
  return (
    <svg
      width={12}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 14"
      {...props}
    >
      <path
        d="M6.004 0c-.565 0-.954.375-.954.933v8.709l.069 1.595-1.808-1.97-1.724-1.646a.988.988 0 00-.68-.272C.39 7.35 0 7.717 0 8.231c0 .242.1.47.298.661l4.996 4.821c.183.184.45.287.71.287a1 1 0 00.702-.287l4.997-4.82A.905.905 0 0012 8.23c0-.514-.39-.882-.908-.882a.988.988 0 00-.679.272L8.697 9.267l-1.808 1.97.06-1.595V.933C6.95.375 6.57 0 6.005 0z"
        fill="#8D8D8D"
      />
    </svg>
  );
}

export default Icon;
