import { useEffect } from 'react';

import styles from './Modal.module.scss';
import HiddenScroll from '@/components/App/HideScroll';

const Modal = ({ isShowModal, onCloseModal, children, outsideStyles }) => {
  const ESC_KEYCODE = 27;

  const closePopup = () => {
    onCloseModal();
  };

  useEffect(() => {
    const onKeydown = e => {
      e.keyCode === ESC_KEYCODE && closePopup();
    };

    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  if (!isShowModal) return null;

  return (
    <>
      <section className={styles.overlay} onClick={closePopup} role="dialog">
        <div className={`${styles.container} ${outsideStyles ? outsideStyles.container : ''}`} onClick={(e) => e.stopPropagation()}>

          <button
            className={`${outsideStyles ? outsideStyles.closeBtn : ''} ${styles.closeBtn} `}
            type="button"
            onClick={closePopup}
          >
            <span className="visually-hidden">Закрыть</span>
          </button>
          {children}

        </div>
      </section>

      <HiddenScroll />
    </>
  );
};

export default Modal;
