import Modal from '../Modal/Modal';
import styles from './ContactModal.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import IconClose from '@/components/ui/svg/IconClose';

function ContactModal({ isShowModal, onCloseModal }) {
  return (
    <Modal outsideStyles={styles} isShowModal={isShowModal} onCloseModal={onCloseModal}>
      <div className={styles.contactForm}>
        <div className={styles.header}>
          <p className={styles.projectName}>Four.44 <sup className={styles.trademark}>®</sup></p>

          <button className={styles.btn} onClick={onCloseModal}>
            <IconClose />
            <span className="visually-hidden">Close modal</span>
          </button>
        </div>

        <h2 className={styles.title}>
          Make today <span className={styles.titleUnderline}><span className={styles.titleBorder}>day one</span></span>
        </h2>
        <p className={styles.subtitle}>No regrets starts now</p>

        <div className={styles.formContainer}>
          <p className={styles.appeal}>
            <span className={styles.iconWrap}><ArrowDown /></span>
            <span className={styles.appealText}>Send us a message</span>
          </p>

          <form action="/">
            <div className={styles.floatingLabelGroup}>
              <input className={styles.input} type="text" name="first_name" id="firstName" placeholder="firstName" required/>
              <label className={styles.floatingLabel} htmlFor="firstName">First Name</label>
            </div>
            <div className={styles.floatingLabelGroup}>
              <input className={styles.input} type="text" name="last_name" id="firstName" placeholder="lastName" required/>
              <label className={styles.floatingLabel} htmlFor="lastName">Last Name</label>
            </div>
            <div className={styles.floatingLabelGroup}>
              <input className={styles.input} type="text" name="company" id="company" placeholder="company" required/>
              <label className={styles.floatingLabel} htmlFor="company">Company</label>
            </div>
            <div className={styles.floatingLabelGroup}>
              <input className={styles.input} type="email" name="email" id="email" placeholder="email" required/>
              <label className={styles.floatingLabel} htmlFor="email">E-mail</label>
            </div>
            <div className={styles.floatingLabelGroup}>
              <textarea className={`${styles.input} ${styles.textarea}`} rows={2} name="message" id="message" placeholder="message" required/>
              <label className={styles.floatingLabel} htmlFor="message">Message</label>
            </div>

            <button className={styles.submitBtn}><span>Send message</span> <ArrowDown /></button>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default ContactModal;
