import { useEffect, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import styles from './Header.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import { useModalStore } from '@/stores/useModalStore';
import HiddenScroll from '@/components/App/HideScroll';
import ArrowDown2 from '@/components/ui/svg/ArrowDown2';
import Container from '@/components/Container/Container';
import ButtonOpenForm from '@/components/ui/ButtonOpenForm/ButtonOpenForm';
import { useHeaderStore } from '@/stores/useHeaderStore';

// to - for inner pages
// link - for outside links or anchors
const navItems = [
  {
    id: uuid4(),
    name: 'Home',
    to: '/',
  },
  {
    id: uuid4(),
    name: 'About Us',
    link: '#about',
  },
  {
    id: uuid4(),
    name: 'Services',
    link: '#services',
    innerItems: [
      {
        id: uuid4(),
        name: 'ai.audit',
        link: '#services',
      },
      {
        id: uuid4(),
        name: 'ai.advise',
        link: '#services-ai-advise',
      },
      {
        id: uuid4(),
        name: 'ai.implement',
        link: '#implement',
      },
      {
        id: uuid4(),
        name: 'ai.performance marketing',
        link: '#build',
      },
    ],
  },
  {
    id: uuid4(),
    name: 'Stay up to date on AI',
    to: '/news/1',
  },
];

const isSetStickyHeader = true;

function Header() {
  const [openItem, setOpenItem] = useState(-1);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
  const { openModal } = useModalStore();
  const { isStickyHeader, setIsStickyHeader } = useHeaderStore();

  useEffect(() => {
    setIsOpenMenu(false);
  }, [isTablet]);

  const handleClickLink = () => {
    setIsOpenMenu(false);
  };

  const handleClickContact = () => {
    setIsOpenMenu(false);
    openModal();
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0) {
      setIsStickyHeader(true);
    } else {
      setIsStickyHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className={`${styles.header} ${(isStickyHeader && isSetStickyHeader) ? styles.headerSticky : ''}`}>
      <Container>
        <div className={styles.wrapper}>
          <p className={styles.projectName}>Four.44 <sup className={styles.trademark}>®</sup></p>

          {!isTablet && (
            <>
              <nav className={styles.nav}>
                <ul className={styles.navList}>
                  {navItems.map((item, index) => (
                    <li
                      className={`${styles.item} ${openItem === index ? styles.itemActive : ''} ${item?.innerItems ? styles.itemMany : ''}`}
                      onMouseEnter={() => setOpenItem(index)}
                      onMouseLeave={() => setOpenItem(-1)}
                      key={item.id}
                    >
                      {item?.link ? (
                        <a className={styles.itemLink} {...(!item?.innerItems && {href: item.link})}>{item.name}</a>
                      ) : (
                        <NavLink
                          className={(props) => `${props.isActive ? styles.itemLinkActive : ''} ${styles.itemLink}`}
                          to={item.to}
                        >
                          {item.name}
                        </NavLink>
                      )}

                      {item?.innerItems && <ArrowDown2 className={styles.itemIcon} />}
                      {(item?.innerItems && openItem === index) && (
                        <ul className={styles.innerItemList}>
                          {item.innerItems.map((innerItem) => (
                            <li key={innerItem.id}>
                              <a className={styles.innerItemLink} href={innerItem.link}>{innerItem.name}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>

              <div className={styles.buttonOpenFormWrap}>
                <ButtonOpenForm />
              </div>
            </>
          )}

          {isTablet && (
            <div className={styles.navContainer}>
              <input
                className={`${styles.checkbox} visually-hidden`}
                onChange={() => setIsOpenMenu((prev) => !prev)}
                type="checkbox"
                id="menu-checkbox"
                checked={isOpenMenu}
              />
              <label className={styles.hamburgerLines} htmlFor="menu-checkbox">
                <span className={`${styles.line} ${styles.line1}`} />
                <span className={`${styles.line} ${styles.line2}`} />
                <span className={`${styles.line} ${styles.line3}`} />
              </label>
            </div>
          )}
        </div>
      </Container>

      {(isTablet && isOpenMenu) && (
        <div className={styles.navModal}>
          <nav className={styles.navMobile}>
            <ul className={styles.navListMobile}>
              {navItems.map((item, index) => (
                <li
                  className={`${styles.itemMobile}`}
                  key={item.id}
                >
                  {item?.link ? (
                    <a className={styles.itemLinkMobile} onClick={handleClickLink} {...(!item?.innerItems && {href: item.link})}>{item.name}</a>
                  ) : (
                    <NavLink
                      className={(props) => `${props.isActive ? styles.itemLinkActive : ''} ${styles.itemLinkMobile}`}
                      to={item.to}
                    >
                      {item.name}
                    </NavLink>
                  )}

                  {item?.innerItems && (
                    <ul className={styles.innerItemListMobile}>
                      {item.innerItems.map((innerItem) => (
                        <li className={styles.innerItemMobile}>
                          <a className={styles.innerItemLinkMobile} onClick={handleClickLink} href={innerItem.link}>
                            <span className={styles.ArrowDownWrap}><ArrowDown width={7} height={8} /></span>
                            <span>{innerItem.name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <button className={styles.btnContact} onClick={handleClickContact} type="button">Contact</button>

          <HiddenScroll />
        </div>
      )}
    </section>
  );
}

export default Header;
