import styles from './MainPage.module.scss';
import Audit from '@/components/Audit/Audit';
import Build from '@/components/Build/Build';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import TopPage from '@/components/TopPage/TopPage';
import Implement from '@/components/Implement/Implement';
import MainSlider from '@/components/MainSlider/MainSlider';
import HowWeDeliver from '@/components/HowWeDeliver/HowWeDeliver';
import InsightsSlider from '@/components/InsightsSlider/InsightsSlider';

function MainPage() {
  return (
    <div className={styles.mainPage}>
      <Header />
      <TopPage />
      <MainSlider />
      <HowWeDeliver />
      <Audit />
      <Implement />
      <Build />
      <InsightsSlider />
      <Footer />
    </div>
  );
}

export default MainPage;
