import dayjs from 'dayjs';
import { Parser } from 'html-to-react';
import { useMediaQuery } from 'react-responsive';

import styles from './Article.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import Container from '@/components/Container/Container';


function Date({ date }) {
  return (
    <p className={styles.date}>Latest news | {dayjs(date).format('DD MMM YY')}</p>
  );
}

function Article({ item }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (!item) return null;
  return (
    <Container>
      <div className={styles.article}>
        <div className={styles.top}>
          {!isMobile && (
            <a className={styles.linkBack} href="/">
              <ArrowDown />
              <span className="visually-hidden">back</span>
            </a>
          )}

          {!isMobile && (
            <Date date={item.publication_date} />
          )}
        </div>

        <div className={styles.content}>
          <div>
            <p className={styles.tag}>{item.tag}</p>
            <h1 className={styles.title}>{item.headline}</h1>
            {isMobile && (
              <Date date={item.publication_date} />
            )}

            <img className={styles.img} src={item.image} alt={item.title} />
          </div>
          <div className={styles.textWrap}>
            {Parser().parse(item.text)}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Article;
