import styles from './TopPage.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import Container from '@/components/Container/Container';
import ButtonScrollDown from '@/components/ui/ButtonScrollDown/ButtonScrollDown';
import ButtonOpenForm from '@/components/ui/ButtonOpenForm/ButtonOpenForm';
import { useMediaQuery } from 'react-responsive';

function TopPage() {
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <Container>
      <section className={styles.topPage}>
        {isTablet && (
          <div className={styles.btnWrap}>
            <ButtonOpenForm />
          </div>
        )}

        <div className={styles.top}>
          <p className={styles.name}>
            <span className={styles.name0}>four.<span className={styles.namePart}>44</span><sup className={styles.trademark}>®</sup></span>
            <span className={styles.name1}>four.<span className={styles.namePart}>44</span><sup className={styles.trademark}>®</sup></span>
            <span className={styles.name2}>four.<span className={styles.namePart}>44</span><sup className={styles.trademark}>®</sup></span>
          </p>
          <p className={styles.textUnderName}>your ai growth partner</p>

          {isTablet && (
            <p className={styles.description}>
              <b>AI</b> is not new and has been powering personalisation engines for many years, giving you real time curated content for products, services and experiences - but we’ve entered a new frontier….<b>ai</b> is no longer just about personalised...
            </p>
          )}

          <ButtonScrollDown link="#dayone" />
        </div>

        <div className={styles.main}>
          <p className={styles.inspiredText}>Inspired by <b>ai</b> and <b>design</b></p>

          {!isTablet && (
            <div>
              <p className={styles.bigText}><span className={styles.bigTextPart}>oneday.</span> dayone.</p>
              <p className={styles.aiChangedText}><b>ai</b> has <b>changed</b> the <b>game</b></p>
              <p className={styles.description}>
                We've all heard of ChatGPT, and possibly other Large Language Models, but how do we leverage them and
                other emerging AI technologies to accelerate our business or service?
              </p>

              <a className={styles.readMore} href="#about"><div className={styles.iconWrap}><ArrowDown /></div>Read more</a>
            </div>
          )}
        </div>
        <div className={styles.mainPage}></div>
      </section>
    </Container>
  );
}

export default TopPage;
