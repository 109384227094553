import * as React from 'react';

function IconClose(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.426.574a1.96 1.96 0 00-2.773 0L10 7.227 3.347.574A1.96 1.96 0 10.574 3.347L7.227 10 .574 16.653a1.96 1.96 0 102.773 2.773L10 12.773l6.653 6.653a1.96 1.96 0 102.773-2.773L12.773 10l6.653-6.653a1.96 1.96 0 000-2.773z"
        fill="#fff"
        fillOpacity={0.5}
      />
    </svg>
  );
}

export default IconClose;
