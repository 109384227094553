import { useMediaQuery } from 'react-responsive';

import styles from './Footer.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import { useModalStore } from '@/stores/useModalStore';
import Container from '@/components/Container/Container';

function Footer() {
  const { openModal } = useModalStore();
  const currentYear = new Date().getFullYear();
  const isMobile = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <Container>
      <footer className={styles.footer}>
        <div className={styles.top}>
          <div className={styles.titleWrap}>
            <p className={styles.title}>let’s talk</p>

            <button className={styles.contactBtn} onClick={openModal}>Contact</button>
          </div>

          {isMobile && (
            <a href="https://wa.me/444444444444" className={styles.contactLink}>
              <p className={styles.contactInner}>
                <span>We are much closer</span>
                <span>Contact us on WhatsApp</span>
              </p>

              <div className={styles.contactIconWrap}>
                <ArrowDown className={styles.contactIcon} />
              </div>
            </a>
          )}
        </div>

        <p className={styles.address}>10 Paternoster Square London EC4M 7LS United Kingdom</p>

        {isMobile && (
          <div className={styles.bottom}>
            <p className={styles.name}>four.44<sup className={styles.trademark}>®</sup></p>
            <p className={styles.copyright}>four.44 © {currentYear}</p>
          </div>
        )}
      </footer>
    </Container>
  );
}

export default Footer;
