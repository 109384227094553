import { v4 as uuid4 } from 'uuid';

import styles from './Build.module.scss';
import Container from '@/components/Container/Container';
import ServicesTop from '@/components/ServicesTop/ServicesTop';
import ServicesCard from '@/components/ServicesCard/ServicesCard';

const implementItems = [
  {
    id: uuid4(),
    title: 'audit',
    subtitle: <><span className={styles.itemSubtitle1}>assess.</span><span className={styles.itemSubtitle2}>learn.</span><span className={styles.itemSubtitle3}>advise.</span></>,
    text: 'We will recommend a number of solutions such as, intelligent content creation, personalised messaging, agile customers journey’s and many more generative experiences that drive growth, possible through AI - it’s a new era and AI is at the centre of it',
  },
  {
    id: uuid4(),
    title: 'build',
    subtitle: <><span className={styles.itemSubtitle1}>build.</span><span className={styles.itemSubtitle2}>integrate.</span><span className={styles.itemSubtitle3}>accelerate.</span></>,
    text: 'Automate time consuming tasks, remove barriers that limit speed of execution and implement AI driven tooling to save time.',
  },
];

function Build() {
  const servicesTop = {
    title: 'ai.build',
    text: 'Where we believe there is not the right tooling available for your needs, or it is not cost effective to integrate we will work with you to define a vision, document the roadmap and detail the requirements. Leveraging our team of designers, developers, testers and analysts we will embark on building a solution that accelerates your business.',
  };

  return (
    <Container className={styles.container} id="build">
      <ServicesTop data={servicesTop} link="#insights" />

      <div className={styles.cards}>
        {implementItems.map((item, index) => (
          <div className={index === 0 ? styles.cardWithArrow : ''} key={item.id}>
            <ServicesCard data={item} index={index + 1} withArrow />
          </div>
        ))}
      </div>
    </Container>
  );
}

export default Build;
