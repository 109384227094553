import * as React from 'react';

function ArrowDown2(props) {
  return (
    <svg
      width={12}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.957 1.727c0-.452.363-.82.814-.82.223 0 .434.093.598.257l3.867 3.984 3.873-3.984a.845.845 0 01.598-.258.818.818 0 01.58 1.389L6.893 6.789c-.194.205-.405.299-.657.299s-.457-.1-.656-.299L1.191 2.295a.773.773 0 01-.234-.568z"
        fill="#767676"
      />
    </svg>
  );
}

export default ArrowDown2;
