import { Route, Routes } from 'react-router-dom';

import MainPage from '@/pages/MainPage/MainPage';
import { useModalStore } from '@/stores/useModalStore';
import NewsArticle from '@/pages/NewsArticle/NewsArticle';
import ContactModal from '@/components/Modals/ContactModal/ContactModal';

function App() {
  const { isOpenModal, closeModal } = useModalStore();

  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/news/:id" element={<NewsArticle />} />
      </Routes>

      <ContactModal isShowModal={isOpenModal} onCloseModal={closeModal} />
    </>
  );
}

export default App;
