import 'swiper/css';
import 'swiper/css/navigation';
import { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './MainSlider.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import Container from '@/components/Container/Container';
import img1 from '@/assets/images/main-slider/main-slider-img1.jpg';
import ButtonOpenForm from '@/components/ui/ButtonOpenForm/ButtonOpenForm';

const slides = [
  {
    id: uuid4(),
    img: img1,
  },
  {
    id: uuid4(),
    img: img1,
  },
  {
    id: uuid4(),
    img: img1,
  },
];

function MainSlider() {
  const [swiper, setSwiper] = useState(null);

  return (
    <section className={styles.mainSlider} id="dayone">
      <Container className={styles.container}>
        <p className={styles.bigText}><span className={styles.bigTextPart}>oneday.</span>dayone.</p>
        <p className={styles.aiChangedText}><b>ai</b> has <b>changed</b> the <b>game</b></p>
        <div className={styles.navigation}>
          <button className={`${styles.navBtn} ${styles.navPrev}`} onClick={() => swiper.slidePrev()}>
            <ArrowDown />
            <span className="visually-hidden">Предыдущий слайд</span>
          </button>
          <button className={`${styles.navBtn} ${styles.navNext}`} onClick={() => swiper.slideNext()}>
            <ArrowDown />
            <span className="visually-hidden">Следующий слайд</span>
          </button>
        </div>
      </Container>

      <div>
        <Swiper
          spaceBetween={20}
          slidesPerView="1"
          className={styles.slider}
          onBeforeInit={(swiper) => setSwiper(swiper)}
        >
          {slides.map((item) => (
            <SwiperSlide className={styles.slide} key={item.id}>
              <img className={styles.sliderImg} src={item.img} alt=""/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default MainSlider;
