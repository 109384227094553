import styles from './Container.module.scss';

function Container({ className, id, children }) {
  return (
    <div className={`${styles._container} ${className ? className : ''}`} id={id}>
      {children}
    </div>
  );
}

export default Container;
