import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import news  from './news.json';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Article from '@/components/Article/Article';
import InsightsSlider from '@/components/InsightsSlider/InsightsSlider';

function NewsArticle() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const findArticle = news.find((item) => item.id === id);

    if (findArticle) {
      setArticle(findArticle);
    }
  }, [id]);

  return (
    <>
      <Header />
      <Article item={article} />
      <InsightsSlider />
      <Footer />
    </>
  );
}

export default NewsArticle;
