import styles from './ButtonOpenForm.module.scss';
import { useModalStore } from '../../../stores/useModalStore';

function ButtonOpenForm({ variant }) {
  const { openModal } = useModalStore();

  return (
    <button
      className={`${styles.button} ${variant === 'light' ? styles.buttonLight : ''}`}
      onClick={openModal}
      type="button"
    >
      Get in touch!
    </button>
  );
}

export default ButtonOpenForm;
