import 'swiper/css';
import 'swiper/css/navigation';
import { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './InsightsSlider.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import Container from '@/components/Container/Container';
import img1 from '@/assets/images/insights-slider/img1.jpg';
import img2 from '@/assets/images/insights-slider/img2.jpg';
import img3 from '@/assets/images/insights-slider/img3.jpg';
import img4 from '@/assets/images/insights-slider/img4.jpg';
import img5 from '@/assets/images/insights-slider/img5.jpg';

const slides = [
  {
    id: uuid4(),
    img: img1,
    title: 'Meta\'s Generative AI Revolutionizes Advertising',
    text: 'Meta has unveiled new generative AI tools tailored for advertisers. These tools will enable brands to create content that\'s more personalized and engaging for their target audiences. With the power of generative AI, advertisers can now create backgrounds, expand images and generate multiple versions of ad text based on their original copy.',
    link: '/news/1',
  },
  {
    id: uuid4(),
    img: img2,
    title: 'Adobe\'s Project Stardust',
    text: 'Adobe has given a sneak peek into Project Stardust, its latest AI-powered photo editing tool. This innovative tool is a new ‘object-aware editing engine’ that can automatically identify and manipulate individual objects in images.',
    link: '/news/1',
  },
  {
    id: uuid4(),
    img: img3,
    title: 'Researchers Share How to Predict AI Minds',
    text: 'A team of researchers is making strides in understanding and controlling AI systems. Their groundbreaking work aims to shed light on the intricate workings of AI, paving the way for more transparent and accountable artificial intelligence. As AI continues to permeate various sectors, such insights are crucial for its ethical and effective application.',
    link: '/news/1',
  },
  {
    id: uuid4(),
    img: img4,
    title: 'Meta\'s Generative AI Revolutionizes Advertising',
    text: 'Meta has unveiled new generative AI tools tailored for advertisers. These tools will enable brands to create content that\'s more personalized and engaging for their target audiences. With the power of generative AI, advertisers can now create backgrounds, expand images and generate multiple versions of ad text based on their original copy.',
    link: '/news/1',
  },
  {
    id: uuid4(),
    img: img5,
    title: 'Adobe\'s Project Stardust',
    text: 'Adobe has given a sneak peek into Project Stardust, its latest AI-powered photo editing tool. This innovative tool is a new ‘object-aware editing engine’ that can automatically identify and manipulate individual objects in images.',
    link: '/news/1',
  },
  {
    id: uuid4(),
    img: img5,
    title: 'Adobe\'s Project Stardust',
    text: 'Adobe has given a sneak peek into Project Stardust, its latest AI-powered photo editing tool. This innovative tool is a new ‘object-aware editing engine’ that can automatically identify and manipulate individual objects in images.',
    link: '/news/1',
  },
];

function InsightsSlider() {
  const [swiper, setSwiper] = useState(null);

  return (
    <section className={styles.insightsSlider} id="insights">
      <Container>
        <h2 className={styles.title}>
          ai.<span className={styles.titleUnderline}><span className={styles.titleBorder}>insights</span></span>
        </h2>
        <p className={styles.subtitle}>Stay ahead. Ai is moving fast.</p>

        <div className={styles.navigation}>
          <button className={`${styles.navBtn} ${styles.navPrev}`} onClick={() => swiper.slidePrev()}>
            <ArrowDown />
            <span className="visually-hidden">Previous slide</span>
          </button>
          <button className={`${styles.navBtn} ${styles.navNext}`} onClick={() => swiper.slideNext()}>
            <ArrowDown />
            <span className="visually-hidden">Next slide</span>
          </button>
        </div>
      </Container>

      <Swiper
        spaceBetween={32}
        slidesPerView="auto"
        className={styles.slider}
        onBeforeInit={(swiper) => setSwiper(swiper)}
      >
        {slides.map((item) => (
          <SwiperSlide className={styles.slide} key={item.id}>
            <img className={styles.slideImg} src={item.img} alt={item.title} />
            <p className={styles.slideTitle}>{item.title}</p>
            <p className={styles.slideText}>{item.text}</p>
            <Link className={styles.slideLink} to={item.link}>
              <span className={styles.slideLinkArrowWrap}>
                <ArrowDown />
              </span>
              <span>Read more</span>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default InsightsSlider;
