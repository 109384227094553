import { useMediaQuery } from 'react-responsive';

import styles from './ServicesTop.module.scss';
import ButtonOpenForm from '../ui/ButtonOpenForm/ButtonOpenForm';
import ButtonScrollDown from '../ui/ButtonScrollDown/ButtonScrollDown';

function ServicesTop({ data, link }) {
  const isMobile = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <div className={styles.servicesTop}>
      <div className={styles.buttonWrap}>
        <p className={styles.label}>services</p>
        {isMobile && <ButtonOpenForm />}
      </div>

      <h2 className={styles.title}>{data.title}</h2>

      <div className={styles.textWrap}>
        <ButtonScrollDown link={link} />
        <p className={styles.text}>{data.text}</p>
      </div>
    </div>
  );
}

export default ServicesTop;
