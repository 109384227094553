import { v4 as uuid4 } from 'uuid';

import styles from './Audit.module.scss';
import Container from '@/components/Container/Container';
import ServicesTop from '@/components/ServicesTop/ServicesTop';
import ServicesCard from '@/components/ServicesCard/ServicesCard';

const auditItems = [
  {
    id: uuid4(),
    title: 'grow',
    subtitle: <span className={styles.itemSubtitle}>use AI to drive growth</span>,
    text: 'We will recommend a number of solutions such as, intelligent content creation, personalised messaging, agile customers journey’s and many more generative experiences that drive growth, possible through AI - it’s a new era and AI is at the centre of it',
  },
  {
    id: uuid4(),
    title: 'time',
    subtitle: <span className={styles.itemSubtitle}>use AI to save time</span>,
    text: 'Automate time consuming tasks, remove barriers that limit speed of execution and implement AI driven tooling to save time.',
  },
  {
    id: uuid4(),
    title: 'cost',
    subtitle: <span className={styles.itemSubtitle}>use AI to reduce cost</span>,
    text: 'AI driven solutions can play a big role in reducing',
  },
];

function Audit() {
  const servicesTop = {
    title: 'ai.audit',
    text: 'We start by assessing your business, learning about challenges, aspirations and your current ways of working and propose ai driven solutions',
  };

  return (
    <Container className={styles.container} id="services">
      <ServicesTop data={servicesTop} link="#implement" />

      <div className={styles.cards}>
        {auditItems.map((item, index) => (
          <ServicesCard data={item} index={index + 1} key={item.id} />
        ))}
      </div>
    </Container>
  );
}

export default Audit;
