import styles from './ButtonScrollDown.module.scss';
import ArrowDown from '../svg/ArrowDown';

function ButtonScrollDown({ link }) {
  return (
    <a href={link} className={styles.link} type="button">
      <ArrowDown />
      <span className="visually-hidden">next</span>
    </a>
  );
}

export default ButtonScrollDown;
