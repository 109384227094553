import { v4 as uuid4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './HowWeDeliver.module.scss';
import ArrowDown from '@/components/ui/svg/ArrowDown';
import Container from '@/components/Container/Container';
import ButtonScrollDown from '@/components/ui/ButtonScrollDown/ButtonScrollDown';

const accordionItems = [
  {
    id: uuid4(),
    anchor: '#services',
    label: <p>ai<span className={styles.labelDivider}>.</span>audit</p>,
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusantium aperiam autem distinctio doloribus eveniet expedita ipsa magnam molestiae nemo nulla perferendis, quasi, quibusdam ratione reiciendis sint tempora, temporibus ut.',
  },
  {
    id: uuid4(),
    anchor: '#advise',
    label: <p>ai<span className={styles.labelDivider}>.</span>advise</p>,
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusantium aperiam autem distinctio doloribus eveniet expedita ipsa magnam molestiae nemo nulla perferendis, quasi, quibusdam ratione reiciendis sint tempora, temporibus ut.',
  },
  {
    id: uuid4(),
    anchor: '#implement',
    label: <p>ai<span className={styles.labelDivider}>.</span>implement</p>,
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusantium aperiam autem distinctio doloribus eveniet expedita ipsa magnam molestiae nemo nulla perferendis, quasi, quibusdam ratione reiciendis sint tempora, temporibus ut.',
  },
  {
    id: uuid4(),
    anchor: '#build',
    label: <p>ai<span className={styles.labelDivider}>.</span>performance marketing</p>,
    text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusantium aperiam autem distinctio doloribus eveniet expedita ipsa magnam molestiae nemo nulla perferendis, quasi, quibusdam ratione reiciendis sint tempora, temporibus ut.',
  },
];

const Accordion = () => {
  const [openedAccordionItem, setOpenedAccordionItem] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const { hash } = location;

  useEffect(() => {
    if (hash) {
      setOpenedAccordionItem(-1);
    }
  }, [hash]);

  const handleClickAccordion = (index) => {
    if (openedAccordionItem === index) {
      setOpenedAccordionItem(-1);
    } else {
      setOpenedAccordionItem(index);
    }

    if (hash) {
      navigate(window.location.pathname);
    }
  };

  return (
    <div className={styles.accordion}>
      {accordionItems.map((el) => (
        <div className={`${styles.accordionItem}`} key={el.id}>
          <a href={el.anchor} className={styles.accordionHead}>
            <b className={styles.accordionLabel}>{el.label}</b>
            <button className={styles.accordionButton}>
              <ArrowDown />
            </button>
          </a>
        </div>
      ))}
    </div>
  );
};

function HowWeDeliver() {
  return (
    <section className={styles.howWeDeliver} id="about">
      <Container>
        <div className={styles.top}>
          <h2 className={styles.title}>
            How we deliver the <span className={styles.titleUnderline}><span className={styles.titleBorder}>ai.impact</span></span>
          </h2>
          <p className={styles.subtitle}>About us</p>

          <ButtonScrollDown link="#services" />
        </div>

        <div className={styles.descWrap}>
          <p className={styles.descLabel}>Overview.</p>

          <div className={styles.descTextWrap}>
            <p>
              We've all heard of ChatGPT, and possibly other Large Language Models, but how do we leverage them and other emerging AI technologies to accelerate our business or service?
            </p>
            <p>
              <b>four.44</b> will work with you to understand your service or product, recommend AI tools, integrations, products or builds that can help transform your business.
            </p>
            <p>
              AI is not new and has been powering personalisation engines for many years, giving you real time curated content for products, services and experiences - but we’ve entered a new frontier…Generative AI, powered by Large Language Models has changed the game.
            </p>
            <p>
              No longer is AI and Machine Learning reserved for businesses with large tech teams and big budgets - Large Language Models can be optimised, integrated and built upon to give businesses of all types a competitive advantage.
            </p>
            <p>
              AI is a '<b>no regrets</b>' move - today is dayone.
            </p>
          </div>
        </div>

        <div className={styles.accordionWrap}>
          <p className={styles.accordionWrapLabel}>Services.</p>

          <div>
            <Accordion />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HowWeDeliver;
